var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-stake-table" },
    [
      _vm.stakes.length
        ? _c("StakesTable", {
            attrs: {
              rows: _vm.stakes,
              pageCount: _vm.pageCount,
              page: _vm.page,
            },
            on: { "update:page": (value) => (_vm.page = value) },
          })
        : _c("p", { staticClass: "my-stake-table__no-data" }, [
            _vm._v("You have never done an unstake before"),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }