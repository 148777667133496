<template>
    <div class="my-stake-table">
        <StakesTable
            v-if="stakes.length"
            :rows="stakes"
            :pageCount="pageCount"
            :page="page"
            @update:page="value => page = value"
        ></StakesTable>
        <p v-else class="my-stake-table__no-data">You have never done an unstake before</p>
    </div>
</template>

<script>
import StakesTable from '@/components/staking/StakesTable.vue';
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'StakeTable',
    components: {
        StakesTable
    },
    watch: {
        page: {
            immediate: true,
            handler(value) {
                this.getStakes({
                    page: value,
                    pageSize: this.pageSize,
                    type: 'unstaked'
                });
            }
        }
    },
    methods: {
        ...mapActions('stakes', ['getStakes']),
    },
    computed: {
        ...mapGetters('stakes', ['stakes', 'count']),
        pageCount() {
            return Math.ceil(this.count / this.pageSize);
        }
    },
    data() {
        return {
            page: 1,
            pageSize: 25,
        }
    }
}
</script>

<style lang="scss" scoped>
.my-stake-table {
    &__no-data {
        font-family: "Tektur";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        color: #ffffff;
    }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
    .my-stake-table {
        &__no-data {
            font-size: 18px;
        }
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .my-stake-table {
        &__no-data {
            font-size: 16px;
        }
    }
}
</style>